import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from '@/router'
import store from '@/store'
import moment from 'moment-timezone'
import 'vue-datetime/dist/vue-datetime.css'

import { sync } from 'vuex-router-sync'
Vue.use(BootstrapVue)
Vue.use(VueCookie)

Vue.filter('timeFormat', function (value) {
  let retVal = ''
  if (value) {
    const min = parseInt(value / 60)
    const sec = value - min * 60
    retVal = min + ':'

    if (sec < 10) {
      retVal += '0'
    }
    retVal += sec
  }
  return retVal
})

Vue.filter('dateFormat', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY.MM.DD LTS')
  }
})

Vue.filter('dateShortFormat', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY.MM.DD')
  }
})

Vue.filter('dateFormatWithNewLine', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY.MM.DD[\r\n]LTS')
  }
})

Vue.filter('phoneFormat', function (value) {
  value = String(value)
  if (!value) {
    return ''
  }

  value = value.replace(/-/g, '')
  if (value.startsWith('00')) {
    value = value.substring(2)
  }

  if (value.startsWith('82') || value.startsWith('86')) {
    value = value.substring(2)
  }

  return value
})

Vue.filter('numberFormat', function (value) {
  if (!value) return '0'
  var regexp = /\B(?=(\d{3})+(?!\d))/g
  return value.toString().replace(regexp, ',')
})
import AuthService from '@/services/AuthService'
import StatusService from '@/services/StatusService'

Vue.mixin({
  methods: {
    getFlagImage(providerId) {
      var srcInfo = {
        1: '/img/flag/world.png',
        2: '/img/flag/852.png',
        3: '/img/flag/1.png',
        4: '/img/flag/79.png',
        5: '/img/flag/81.png',
      }

      return srcInfo[providerId] ? srcInfo[providerId] : `/img/flag${providerId}.png`
    },
    async getUserInfo() {
      try {
        const response = await AuthService.getUser(this.$cookie.get('redboysms_token'))
        const { user } = response.data
        await this.$store.dispatch('setUser', user)

        var Tawk_API = window.Tawk_API || {}
        Tawk_API.onLoad = function () {
          Tawk_API.setAttributes(
            {
              name: `${user.username}(${user.email})`,
              email: user.email,
              hash: user.tawkKey,
            },
            function (err) {
              console.log(err)
            }
          )
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getStatus() {
      try {
        const response = await StatusService.getStatus()
        this.$store.dispatch('setStatus', response.data)
      } catch (e) {
        // Do nothing
      }
    },
    showSpinner() {
      clearTimeout(this.spinnerTimer)
      const vm = this
      this.spinnerTimer = setTimeout(function () {
        vm.$store.dispatch('showSpinner', true)
      }, 400)
    },
    hideSpinner() {
      clearTimeout(this.spinnerTimer)
      const vm = this
      this.spinnerTimer = setTimeout(function () {
        vm.$store.dispatch('showSpinner', false)
      }, 400)
    },
    logout() {
      this.$cookie.delete('redboysms_token')
      this.$store.dispatch('setToken', null)
      this.$store.dispatch('setUser', null)
      location.href = '/'
    },
  },
})

sync(store, router)

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/errors/404')
  } else {
    next()
  }
})

/* eslint-disable no-new */
var app = new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App,
  },
})

window.app = app
