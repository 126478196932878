import axios from 'axios'

export default (token, option = {}) => {
  var data = {
    headers: {},
  }

  if (!token && window.app.$cookie) {
    token = window.app.$cookie.get('redboysms_token')
  }

  if (token) {
    data.headers.Authorization = 'bearer ' + token
  }

  if (option.timeout) {
    data.timeout = option.timeout
  }

  var instance = axios.create(data)

  instance.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response
    },
    function (error) {
      // Do something with response error
      if (error.response) {
        console.log('interceptors', error.response)
        var status = error.response.status
        var data = error.response.data
        if (status === 401) {
          window.app.logout()
        } else {
          var err = data.error
          if (!err) {
            if (status == 401) {
              err = 'Unauthorized error'
            } else if (status == 500) {
              err = 'Internal server error'
            } else {
              err = '错误[' + status + ']: 请咨询管理员'
            }
          }

          if (!option.hideAlert) {
            alert(err)
          }
        }
      }
      return Promise.reject(error)
    }
  )
  return instance
}
